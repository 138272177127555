exports.components = {
  "component---src-pages-factions-js": () => import("./../../../src/pages/factions.js" /* webpackChunkName: "component---src-pages-factions-js" */),
  "component---src-pages-game-js": () => import("./../../../src/pages/game.js" /* webpackChunkName: "component---src-pages-game-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-litepaper-js": () => import("./../../../src/pages/litepaper.js" /* webpackChunkName: "component---src-pages-litepaper-js" */),
  "component---src-pages-tokenomics-js": () => import("./../../../src/pages/tokenomics.js" /* webpackChunkName: "component---src-pages-tokenomics-js" */),
  "component---src-pages-wallet-js": () => import("./../../../src/pages/wallet.js" /* webpackChunkName: "component---src-pages-wallet-js" */),
  "component---src-templates-team-member-page-template-js": () => import("./../../../src/templates/TeamMemberPageTemplate.js" /* webpackChunkName: "component---src-templates-team-member-page-template-js" */)
}

